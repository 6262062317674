import request from '@/utils/request'

// 创建短信模版
export function createSmsTemplate(data) {
  return request({
    url: `/sms_templates`,
    method: 'post',
    data
  })
}

// 查询短信模版
export function findSmsTemplates(params) {
  return request({
    url: `/sms_templates`,
    method: 'get',
    params
  })
}

// 更新短信模版有效性
export function updateSmsTemplateEffective(smsTemplateId, data) {
  return request({
    url: `/sms_templates/${smsTemplateId}/effective`,
    method: 'patch',
    data
  })
}

// 更新短信模版有效性
export function findSmsTemplateForm(smsTemplateId) {
  return request({
    url: `/sms_templates/${smsTemplateId}/form`,
    method: 'get'
  })
}

// 更新短信模版
export function updateSmsTemplate(smsTemplateId, data) {
  return request({
    url: `/sms_templates/${smsTemplateId}`,
    method: 'put',
    data
  })
}

// 查询短信模版
export function findSmsTemplateOptionsByCarrierType(params) {
  return request({
    url: `/sms_templates/options`,
    method: 'get',
    params
  })
}

// 查询可导入的客户通用短信模版
export function findImportableSmsTemplates(params) {
  return request({
    url: `/sms_templates/importable`,
    method: 'get',
    params: params
  })
}
